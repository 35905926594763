import React, { useState } from 'react';
import { Box, Button, TextField, IconButton, CircularProgress, InputAdornment } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import axiosInstance from '../../API/axiosInstance';

const ChatSender = ({ channel, setMessages }) => {
    const [newMessage, setNewMessage] = useState('');
    const [files, setFiles] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [uploadCompleteMessage, setUploadCompleteMessage] = useState(false);

    // Handle message send along with file uploads
    const handleSendMessage = async (e) => {
        e.preventDefault();

        try {
            const token = localStorage.getItem('accessToken');
            if (!token) throw new Error('Token not available.');

            // Check if there is a file to send, or message content
            if (files.length === 0 && !newMessage.trim()) {
                throw new Error('Message content or file is required.');
            }

            const formData = new FormData();
            formData.append('text', newMessage.trim());
            files.forEach((file) => formData.append('files', file));

            setUploading(true);  // Start the upload

            // Sending the request with progress tracking
            const response = await axiosInstance.post(`/api/message/channel/${channel._id}/send`, formData, {
                headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' }
            });

            if (response.data.success) {
                setMessages((prevMessages) => [...prevMessages, response.data.message]);
                setNewMessage(''); // Reset message input
                setFiles([]); // Clear selected files
                setUploadCompleteMessage(true); // Show upload complete message

                // Hide the upload complete message after 3 seconds
                setTimeout(() => {
                    setUploadCompleteMessage(false);
                }, 3000);
            } else {
                throw new Error('Failed to send message');
            }
        } catch (error) {
            console.error(error);
            setUploading(false);  // If error occurs, stop the upload progress
        } finally {
            setUploading(false);  // Ensure uploading state is reset after the message is sent
        }
    };

    // Handle file input change (adds files)
    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        const validFiles = selectedFiles.filter((file) => true); // Add validation logic if needed
        setFiles((prevFiles) => [...prevFiles, ...validFiles]);
    };

    // Handle message input change
    const handleMessageChange = (e) => {
        setNewMessage(e.target.value);
    };

    return (
        <Box component="form" onSubmit={handleSendMessage} sx={{ display: 'flex', alignItems: 'center', gap: 2, justifyContent: 'center', width: '100%' }}>
            {/* Text Field with Attachment Icon Inside */}
            <TextField
                variant="outlined"
                value={newMessage}
                onChange={handleMessageChange}
                fullWidth
                sx={{
                    '& input': {

                    },
                    '& fieldset': {
                    },
                    flexGrow: 1, // Allow the text field to take up the remaining space
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <IconButton component="label" sx={{ p: 0 }}>
                                <AttachFileIcon />
                                <input type="file" hidden multiple onChange={handleFileChange} />
                            </IconButton>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                type="submit"
                                color="secondary"
                                sx={{ width: '40px', height: '40px', padding: 0 }} // Adjust size of button
                                disabled={uploading || (!newMessage.trim() && files.length === 0)} // Disable if uploading and no message or file
                            >
                                {uploading ? <CircularProgress size={24} /> : <SendIcon />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />

            {/* Show Upload Complete Message */}
            {uploadCompleteMessage && (
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 16,
                        left: 16,
                        bgcolor: 'success.main',
                        p: 2,
                        borderRadius: 1,
                        boxShadow: 3,
                    }}
                >
                    <span style={{ color: 'white' }}>Upload Complete!</span>
                </Box>
            )}
        </Box>
    );
};

export default ChatSender;
