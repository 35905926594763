import React, { useState, useEffect } from 'react';  // <-- Ensure useEffect is imported
import { Box, Typography, Container, useMediaQuery } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import Navigation from './Navigation';
import Sidebar from './Sidebar';
import Chat from '../components/Chat/Chat';

const Dashboard = () => {
    const { user, logout } = useAuth();
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [selectedChannel, setSelectedChannel] = useState(null);
    const [channels, setChannels] = useState([]); // New state to manage channels
    const isMobile = useMediaQuery('(max-width: 600px)');
    const [currentChannel, setCurrentChannel] = useState(null); // To store the current active channel

    // Function to handle channel selection
    const handleChannelSelect = (channel) => {
        setSelectedChannel(channel);
        setCurrentChannel(channel);  // Update the current channel
        localStorage.setItem('lastUsedChannel', channel);  // Save to localStorage
        console.log("Selected channel:", channel);
    };

    // Function to handle new channel creation
    const handleChannelCreate = (newChannel) => {
        setChannels((prevChannels) => [...prevChannels, newChannel]);
    };

    const handleSidebarToggle = () => {
        setSidebarOpen(!sidebarOpen);
    };

    // UseEffect to check localStorage when the component mounts
    useEffect(() => {
        // Try to get the last used channel from localStorage
        const lastUsedChannel = localStorage.getItem('lastUsedChannel');
        
        // If a channel exists in localStorage, set it as the current channel
        if (lastUsedChannel) {
            setCurrentChannel(lastUsedChannel);
            setSelectedChannel(lastUsedChannel);  // Ensure selected channel is updated
        } else {
            // If no channel is stored, set the default channel (e.g., 'general')
            const defaultChannel = 'general';  // Default channel fallback
            setCurrentChannel(defaultChannel);
            setSelectedChannel(defaultChannel);
            localStorage.setItem('lastUsedChannel', defaultChannel);  // Save default to localStorage
        }
    }, []);  // Empty dependency array, runs once on mount

    return (
        <Box sx={{ display: 'flex', height: '100vh' }}>
            {sidebarOpen && (
                <Box sx={{ width: isMobile ? '100%' : '250px', backgroundColor: '#f5f5f5', padding: 2 }}>
                    <Sidebar
                        isSidebarOpen={sidebarOpen}
                        onChannelSelect={handleChannelSelect}  // Update the selected channel when a user selects it
                        onChannelCreate={handleChannelCreate}
                        channels={channels}
                    />
                </Box>
            )}

            <Box sx={{ flex: 1, padding: 0 }}>
                <Box>
                    <Navigation onToggle={handleSidebarToggle} />
                </Box>

                <Container sx={{ marginTop: '60px' }}>
                    <Typography variant="h4" gutterBottom>
                        {/* Welcome to your Dashboard, {user.email || 'User'} */}
                    </Typography>

                    {/* Pass currentChannel to the Chat component to auto-activate the channel */}
                    <Chat channel={currentChannel} />
                </Container>
            </Box>
        </Box>
    );
};

export { Dashboard };
