import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'; // Import your auth context

const PrivateRoute = ({ children }) => {
    const { user, loading } = useAuth(); // Get the current user from context

    if (loading) {
        // Optional: If there's a loading state (while checking authentication), you can show a loading spinner or something
        return <div>Loading...</div>;
    }

    if (!user) {
        // If the user is not authenticated, redirect to login
        return <Navigate to="/login" />;
    }

    // If the user is authenticated, render the children (the protected component)
    return children;
};

export default PrivateRoute;
