import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext'; // Assuming you have an AuthContext
import ChatSender from './ChatSender';
import Message from './Message';
import axiosInstance from '../../API/axiosInstance';

const Chat = ({ channel }) => {
    const { user } = useAuth();  // Assuming user info is needed for some reason (e.g., to display who sent the message)
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch messages when the channel is selected or changes
    useEffect(() => {
        if (!channel || !channel._id) {
            console.error('Invalid channel or channel ID');
            return; // Prevent fetching if channel or channel._id is not valid
        }

        // Async function to fetch messages
        const fetchMessages = async () => {
            setLoading(true);
            setError(null);  // Reset error on every new fetch attempt

            try {
                const token = localStorage.getItem('accessToken');
                if (!token) throw new Error('Token is required.');

                const response = await axiosInstance.get(`/api/message/channel/${channel._id}/messages`, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                if (response.data.success) {
                    setMessages(response.data.messages); // No reverse, show as fetched
                } else {
                    throw new Error('Failed to fetch messages');
                }
            } catch (error) {
                setError(error.message || 'An error occurred while fetching messages');
            } finally {
                setLoading(false);
            }
        };

        fetchMessages();
    }, [channel]);  // Dependency array to trigger this when `channel` changes

    // Handle loading state
    if (loading) {
        return (
            <Box sx={{ textAlign: 'center', marginTop: 4 }}>
                <CircularProgress />
                <Typography>Loading messages...</Typography>
            </Box>
        );
    }

    // Handle error state
    if (error) {
        return (
            <Box sx={{ textAlign: 'center', marginTop: 4 }}>
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '80vh' }}>
            {/* Channel name */}
            <Box sx={{ padding: 2 }}>
                <Typography variant="h5" gutterBottom>
                    {channel?.name || 'No Channel Selected'}
                </Typography>
            </Box>

            {/* Message Display */}
            <Box sx={{ flex: 1, overflowY: 'auto', padding: 2 }}>
                {messages.length > 0 ? (
                    messages.map((message) => (
                        <Message key={message._id} message={message} />
                    ))
                ) : (
                    <Typography>No messages available</Typography>
                )}
            </Box>

            {/* ChatSender for sending new messages */}
            <Box sx={{ padding: 2, borderTop: '1px solid #ddd' }}>
                <ChatSender channel={channel} setMessages={setMessages} />
            </Box>
        </Box>
    );
};

export default Chat;
