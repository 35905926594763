import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Container, TextField, CircularProgress } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import Navigation from './Navigation';
import Sidebar from './Sidebar';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';

const RSS = () => {
    const { user, logout } = useAuth();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [rssData, setRssData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false); // Loading state for RSS feed fetching
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    const handleSidebarToggle = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const feedUrl = "https://www.nomadicmatt.com/feed/";

    // Function to fetch RSS Feed
    const fetchRSSFeed = async () => {
        setLoading(true); // Set loading to true before fetching
        setError(null); // Reset errors before fetching
        try {
            const response = await fetch(feedUrl);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const text = await response.text(); // Get the response as text
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(text, "application/xml"); // Parse the XML response

            const items = xmlDoc.getElementsByTagName("item");
            const data = Array.from(items).map(item => ({
                title: item.getElementsByTagName("title")[0].textContent,
                description: item.getElementsByTagName("description")[0].textContent,
                link: item.getElementsByTagName("link")[0].textContent,
                pubDate: item.getElementsByTagName("pubDate")[0].textContent,
            }));

            setRssData(data);
            setFilteredData(data); // Initially set filtered data to all RSS data
        } catch (error) {
            setError('Error fetching RSS feed: ' + error.message);
        } finally {
            setLoading(false); // Set loading to false after fetching
        }
    };

    useEffect(() => {
        if (user) {
            fetchRSSFeed(); // Fetch RSS feed when component mounts
        }
    }, [user]);

    // Redirect to login if user is not authenticated
    if (!user) {
        return (
            <Box sx={{ padding: 3 }}>
                <Typography variant="h6" color="error">
                    You need to log in to view the RSS feed.
                </Typography>
                <Button onClick={() => navigate('/login')} variant="contained" sx={{ marginTop: 2 }}>
                    Go to Login
                </Button>
            </Box>
        );
    }

    // Search handler with debounce
    const handleSearch = debounce((e) => {
        const query = e.target.value;
        setSearchTerm(query);

        if (query.trim() === '') {
            setFilteredData(rssData); // Reset to all data when search is empty
        } else {
            const filtered = rssData.filter(item =>
                item.title.toLowerCase().includes(query.toLowerCase()) ||
                item.description.toLowerCase().includes(query.toLowerCase())
            );
            setFilteredData(filtered);
        }
    }, 300);

    return (
        <Box sx={{ display: 'flex', height: '100vh' }}>
            {sidebarOpen && (
                <Box sx={{ width: '250px', backgroundColor: '#f5f5f5', padding: 2 }}>
                    <Sidebar isSidebarOpen={sidebarOpen} toggleSidebar={handleSidebarToggle} />
                </Box>
            )}

            <Box sx={{ flex: 1, padding: 0 }}>
                <Navigation onToggle={handleSidebarToggle} />

                <Container>
                    <Typography variant="h4" sx={{ marginTop: 3 }}>Travel Deals</Typography>

                    {error ? (
                        <Typography color="error">Error: {error}</Typography>
                    ) : loading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                            <CircularProgress />
                        </Box>
                    ) : rssData.length === 0 ? (
                        <Typography>No RSS data found.</Typography>
                    ) : (
                        <Box>
                            <TextField
                                label="Search"
                                variant="outlined"
                                fullWidth
                                value={searchTerm}
                                onChange={handleSearch}
                                sx={{ marginBottom: 3 }}
                            />
                            {filteredData.length === 0 ? (
                                <Typography>No results found</Typography>
                            ) : (
                                filteredData.map((item, index) => (
                                    <Box key={index} sx={{ marginBottom: 2 }}>
                                        <Typography variant="h6" component="a" href={item.link} target="_blank" sx={{ textDecoration: 'none', color: 'primary.main' }}>
                                            {item.title}
                                        </Typography>
                                        <Typography variant="body2" sx={{ marginTop: 1 }}>
                                            {item.description}
                                        </Typography>
                                        <Typography variant="caption" color="textSecondary" sx={{ marginTop: 1 }}>
                                            {item.pubDate}
                                        </Typography>
                                    </Box>
                                ))
                            )}
                        </Box>
                    )}
                </Container>
            </Box>
        </Box>
    );
};

export default RSS;
