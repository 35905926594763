import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Typography, List, ListItem, ListItemText, Modal, TextField } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import useChannels from '../hooks/hooksChannels';
import axiosInstance from '../API/axiosInstance';

const Sidebar = ({ isSidebarOpen, toggleSidebar, onChannelSelect }) => {
    const { user } = useAuth();
    const [activeChannel, setActiveChannel] = useState(null);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [showCreateChannel, setShowCreateChannel] = useState(false);
    const { channels, error, setChannels } = useChannels();

    const inputRef = useRef(null);  // Create a ref for the TextField

    // Focus the input field when modal is opened
    useEffect(() => {
        if (showCreateChannel && inputRef.current) {
            inputRef.current.focus();  // Focus the input when the modal is opened
        }
    }, [showCreateChannel]);  // Run the effect whenever the modal state changes

    // Load the last selected channel from localStorage on component mount
    useEffect(() => {
        const lastUsedChannelId = localStorage.getItem('lastUsedChannelId');
        if (lastUsedChannelId) {
            const lastChannel = channels.find((channel) => channel._id === lastUsedChannelId);
            if (lastChannel) {
                setActiveChannel(lastChannel);
                onChannelSelect(lastChannel); // Notify parent component of the channel selection
            }
        }
    }, [channels, onChannelSelect]);

    const handleChannelSelect = (channel) => {
        setActiveChannel(channel);
        onChannelSelect(channel); // Callback to notify parent component of the channel selection

        // Save the selected channel to localStorage
        localStorage.setItem('lastUsedChannelId', channel._id);
    };

    const createChannel = async () => {
        if (!name) return;

        try {
            const channelData = { name, description: description.trim() };
            const response = await axiosInstance.post('/api/channel', channelData);  // Assuming POST to create a channel
            setChannels(prev => [...prev, response.data]);  // Add newly created channel to the list
            setName('');
            setDescription('');
            setShowCreateChannel(false);  // Close modal after channel creation
        } catch (err) {
            console.error("Error creating channel:", err);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            createChannel();  // Call the createChannel function when Enter is pressed
        }
    };

    const deleteChannel = async (channelId) => {
        try {
            await axiosInstance.delete(`/api/channel/${channelId}`);  // Assuming DELETE to remove a channel
            setChannels(prev => prev.filter((channel) => channel._id !== channelId));
            if (activeChannel?._id === channelId) {
                setActiveChannel(null);
                onChannelSelect(null); // Reset active channel in parent
                localStorage.removeItem('lastUsedChannelId'); // Clear the last used channel in localStorage
            }
        } catch (err) {
            console.error("Error deleting channel:", err);
        }
    };

    return (
        <Box
            sx={{
                width: isSidebarOpen ? 250 : 0,
                maxHeight: '96vh',
                transition: 'width 0.3s',
                overflow: 'hidden',
                bgcolor: 'grey.100',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            {/* Button to toggle the creation of new channels */}
            <Button
                variant="outlined"
                onClick={() => setShowCreateChannel(!showCreateChannel)}
                sx={{ margin: 2, width: '80%' }}
            >
                {showCreateChannel ? '✕' : '＋ Channel'}
            </Button>

            {/* Sidebar content only visible if sidebar is open */}
            {isSidebarOpen && (
                <>
                    {/* Error message if there is any */}
                    {error && (
                        <Typography color="error" sx={{ mb: 2 }}>
                            {error}
                        </Typography>
                    )}

                    {/* List of channels */}
                    <List sx={{ overflowY: 'auto', flexGrow: 1, maxHeight: 'calc(96vh - 150px)' }}>
                        {channels.map((channel) => (
                            <ListItem
                                key={channel._id}
                                onClick={() => handleChannelSelect(channel)}
                                button
                                sx={{
                                    cursor: 'pointer', // Add hand cursor on hover
                                    backgroundColor: activeChannel?._id === channel._id ? 'lightblue' : 'transparent', // Highlight active channel
                                }}
                            >
                                <ListItemText
                                    primary={channel.name}
                                    // secondary={channel.description || '_'}
                                    sx={{ textAlign: 'left' }}
                                />
                                {/* Button to delete the channel */}
                                <Button
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevent triggering channel select on delete button click
                                        deleteChannel(channel._id);
                                    }}
                                    aria-label={`Delete ${channel.name}`}
                                    color="error"
                                    sx={{
                                        borderRadius: '50%',
                                        minWidth: '30px',
                                        width: '30px',
                                        height: '30px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    🗑️
                                </Button>
                            </ListItem>
                        ))}
                    </List>

                    {/* Modal to create a new channel */}
                    <Modal
                        open={showCreateChannel}
                        onClose={() => setShowCreateChannel(false)}
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                p: 4,
                                borderRadius: 2,
                                boxShadow: 24,
                                width: 300,
                            }}
                        >
                            <Button
                                onClick={() => setShowCreateChannel(false)}
                                sx={{ position: 'absolute', right: 10, top: 10 }}
                                aria-label="Close modal"
                            >
                                ✕
                            </Button>
                            <Typography variant="h6">Create New Channel</Typography>
                            <TextField
                                label="Channel Name"
                                variant="outlined"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                onKeyPress={handleKeyPress}  // Add onKeyPress event listener
                                ref={inputRef}  // Attach the ref to the TextField
                                fullWidth
                                sx={{ mt: 2 }}
                            />

                            <Button
                                onClick={createChannel}
                                disabled={!name}
                                variant="contained"
                                sx={{ mt: 2 }}
                            >
                                Create Channel
                            </Button>
                        </Box>
                    </Modal>
                </>
            )}
        </Box>
    );
};

export default Sidebar;
