import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';  // Import ThemeProvider
import { AuthProvider } from './contexts/AuthContext';
import { Dashboard } from './components/Dashboard';  // Named import
import { Profile } from './components/Profile';  // Named import

import Login from './components/Login';
import RSS from './components/Rss';
import Oferti from './components/Oferti';
import theme from './theme';  // Import the theme

import PrivateRoute from './routes/PrivateRoute';  // Import PrivateRoute component

const App = () => {
    // Log the theme to the console to check if it's loaded properly
    console.log('Current Theme:', theme);  // This will print the theme object

    return (
        <ThemeProvider theme={theme}>
            <AuthProvider>
                <Router>
                    <Routes>
                        {/* Public Routes */}
                        <Route path="/login" element={<Login />} />
                        <Route path="/oferti" element={<Oferti />} />

                        {/* Private Route for Dashboard */}
                        <Route 
                            path="/dashboard" 
                            element={
                                <PrivateRoute>
                                    <Dashboard />
                                </PrivateRoute>
                            } 
                        />

                        {/* Private Route for RSS */}
                        <Route 
                            path="/rss" 
                            element={
                                <PrivateRoute>
                                    <RSS />
                                </PrivateRoute>
                            } 
                        />

                        {/* Private Route for Profile */}
                        <Route 
                            path="/profile" 
                            element={
                                <PrivateRoute>
                                    <Profile />
                                </PrivateRoute>
                            } 
                        />

                        {/* Redirect from root */}
                        <Route path="/" element={<Navigate to="/dashboard" />} />
                    </Routes>
                </Router>
            </AuthProvider>
        </ThemeProvider>
    );
};

export default App;
