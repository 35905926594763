import React from 'react';
import { AppBar, Toolbar, IconButton, Box, Typography, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'; // Icon for the toggle button
import { Link, NavLink, useLocation } from 'react-router-dom';  // Import NavLink
import { useAuth } from '../contexts/AuthContext';

const Navigation = ({ onToggle }) => {  // Pass a function to handle toggle button click
    const { user, logout } = useAuth();
    const location = useLocation();

    const handleLogout = () => {
        logout();
    };

    return (
        <AppBar
            position="sticky"
            sx={{
                display: 'flex',
                width: '100%', // Ensure the AppBar stretches to full width
                maxWidth: '100%', // In case the parent has restrictions
            }}
        >
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', paddingX: { xs: 1, sm: 2 } }}>
                {/* Left-aligned Toggle Button */}
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={onToggle}  // Call the toggle function when clicked
                    sx={{
                        mr: 2,
                        width: { xs: '40px', sm: '50px' }, // Adjust width for mobile and larger screens
                        height: { xs: '40px', sm: '50px' }, // Adjust height similarly
                    }}
                >
                    <MenuIcon sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }} /> {/* Adjust icon size */}
                </IconButton>

                {/* Center-aligned Brand or Logo */}
                <Typography
                    variant="h6"
                    component="div"
                    sx={{
                        flexGrow: 1,
                        fontSize: { xs: '1.25rem', sm: '1.5rem' },  // Adjust font size for small screens
                    }}
                >
                    🍀 {/* Logo/Brand */}
                </Typography>

                {/* Right-aligned Navigation Links */}
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    {/* Dashboard Link */}
                    <NavLink
                        to="/dashboard"
                        style={({ isActive }) => ({
                            color: isActive ? 'primary' : 'inherit',  // Apply active color if active
                            fontSize: '1rem',
                        })}
                    >
                        <Button sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>Dashboard</Button>
                    </NavLink>
                    
                    {/* Oferti Link */}
                    <NavLink
                        to="/oferti"
                        style={({ isActive }) => ({
                            color: isActive ? 'primary' : 'inherit',
                            fontSize: '1rem',
                        })}
                    >
                        <Button sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>Oferti</Button>
                    </NavLink>

                    {/* Profile Link */}
                    <NavLink
                        to="/profile"
                        style={({ isActive }) => ({
                            color: isActive ? 'primary' : 'inherit',
                            fontSize: '1rem',
                        })}
                    >
                        <Button sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>Profile</Button>
                    </NavLink>

                    {/* RSS Link */}
                    <NavLink
                        to="/rss"
                        style={({ isActive }) => ({
                            color: isActive ? 'primary' : 'inherit',
                            fontSize: '1rem',
                        })}
                    >
                        <Button sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>RSS</Button>
                    </NavLink>

                    {/* Logout Button, only if user is authenticated */}
                    {user && (
                        <Button
                            onClick={handleLogout}
                            color="inherit"
                            sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}
                        >
                            Logout
                        </Button>
                    )}
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Navigation;
