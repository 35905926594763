import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, Button, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext'; // Assuming you have user details in context

// Placeholder thumbnail for unsupported file types
const placeholderThumbnail = 'path/to/placeholder-image.png'; // Update this path with an actual image URL

const Message = ({ message }) => {
    const { user } = useAuth(); // Access user data from context (email, name, etc.)
    const [senderEmail, setSenderEmail] = useState('');
    const [openPreview, setOpenPreview] = useState(false); // Modal open state
    const [previewFile, setPreviewFile] = useState(null); // State to hold the file to preview
    
    // Ref for auto-scrolling to the current message
    const messagesEndRef = useRef(null);

    // Set sender email if the sender's email is available
    useEffect(() => {
        setSenderEmail(message.email || user?.email || 'Unknown Email');
    }, [message, user]);

    // Auto-scroll to the current message when it's added or updated
    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [message]);

    // Handle opening the modal to preview the file
    const handleOpenPreview = (file) => {
        setPreviewFile(file); // Set the file to preview
        setOpenPreview(true); // Open the modal
    };

    // Handle closing the modal
    const handleClosePreview = () => {
        setOpenPreview(false); // Close the modal
        setPreviewFile(null); // Clear the preview file
    };

    return (
        <Box 
            sx={{ display: 'flex', flexDirection: 'column', marginTop: 4 }} 
            ref={messagesEndRef} // Attach the ref to the current message
        >
            {/* Message Content */}
            <Typography variant="body1">{message.content || 'Attachment only'}</Typography>

            {/* Attachments (if any) */}
            {message.fileUrl && Array.isArray(message.fileUrl) && message.fileUrl.length > 0 && (
                <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 1, alignItems: 'flex-end' }}> {/* Added alignItems: 'flex-end' to align on the right */}
                    {message.fileUrl.map((file, index) => {
                        const fileUrl = file.url || placeholderThumbnail; // Use file.url if available, otherwise use placeholder

                        return (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 1 }}>
                                {/* Display file preview */} 
                                <Box
                                    sx={{
                                        width: 100,
                                        height: 100,
                                        overflow: 'hidden',
                                        borderRadius: '4px',
                                        marginRight: 1,
                                    }}
                                >
                                    <img
                                        src={message.fileUrl} // Use the fileUrl directly (no need to check for image type)
                                        alt={`file-preview-${message.message_id}-${index}`} // Alt method for file URL in the preview
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                            cursor: 'pointer', // Make it clickable
                                        }}
                                        onClick={() => handleOpenPreview(file)} // Open the preview modal on click
                                    />
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            )}

            {/* Message Info in Table Format */}
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table size="small">
                    <TableBody>
                        {/* Sender */}
                        <TableRow>
                            <TableCell sx={{ fontSize: '0.45rem', textAlign: 'right' }}>
                                User: {senderEmail || 'Unknown Email'}
                            </TableCell>
                        </TableRow>

                        {/* Timestamp */}
                        <TableRow>
                            <TableCell sx={{ fontSize: '0.45rem', textAlign: 'right' }}>
                                {new Date(message.timestamp).toLocaleString() || 'N/A'}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Modal for Preview */}
            <Dialog open={openPreview} onClose={handleClosePreview}>
                <DialogTitle></DialogTitle>
                <DialogContent>
                    <img
                        src={message.fileUrl || placeholderThumbnail}
                        alt="Preview"
                        style={{ width: '100%', height: 'auto' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosePreview} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Message;
