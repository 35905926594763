import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#6400ff',   // Primary color
            dark: '#115293',    // Darker shade for primary
            active: '#e0f7fa',  // Light cyan for active background
        },
        text: {
            primary: '#000',    // Primary text color
            secondary: '#666',  // Secondary text color
        },
        background: {
            input: '#f5f5f5',   // Default gray background for inputs
        },
    },
    typography: {
        activeFontWeight: 'bold',   // Font weight for active items
        textDecoration: 'none',     // Default text decoration for buttons
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 20,          // Set default border radius for all buttons
                    textDecoration: 'none',    // Ensure no text decoration by default
                    '&:hover': {
                        backgroundColor: 'rgba(100, 0, 255, 0.8)', // Light hover effect
                    },
                    '&.active': {
                        backgroundColor: 'rgba(100, 0, 255, 0.2)', // Active background color
                        fontWeight: 'bold',     // Bold font weight when active
                    },
                    transition: 'background-color 0.3s ease', // Smooth transition for background color
                },
            },
        },
        // Override styles for MuiTextField to remove underline/strikethrough globally
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-root': {
                        borderBottom: 'none', // Remove the underline on all text fields
                        transition: 'background-color 0.3s ease', // Smooth transition for background color

                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '&.MuiOutlinedInput-root': {
                    },
                    '& input': {
                        textDecoration: 'none', // Make sure there's no strikethrough
                        backgroundColor: '#f9f9f9', // Light grey background
                        borderRadius: '30px', // Rounded border for the fieldset (outer outline)
                        borderColor: '#f9f9f9', // Rounded border for the fieldset (outer outline)
                        margin: 10,

                    },
                    '& fieldset': {
                        borderRadius: '30px', // Rounded border for the fieldset (outer outline)


                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: '#f5f5f5', // Gray background for outlined input fields
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#6400ff', // Optional: change border color on hover
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#6400ff', // Optional: border color on focus
                    },
                },
                input: {
                    backgroundColor: '#f5f5f5', // Ensure inner input also has gray background
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    backgroundColor: '#f5f5f5', // Gray background for filled input fields
                    '&:hover': {
                        backgroundColor: '#e0e0e0', // Slightly darker gray on hover
                    },
                    '&.Mui-focused': {
                        backgroundColor: '#f5f5f5', // Keep the background consistent when focused
                    },
                },
            },
        },
    },
});

export default theme;
