import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, TextField, Button, Avatar, Grid, Paper } from '@mui/material';
import { useAuth } from '../contexts/AuthContext'; // Assuming AuthContext provides user data
import axiosInstance from '../API/axiosInstance'; // Assuming axiosInstance is properly set up for API calls
import Navigation from './Navigation'; // Import the Navigation component (Header)
import Sidebar from './Sidebar'; // Import Sidebar Component

const Profile = () => {
    const { user, logout } = useAuth(); // Access user data from context
    const [name, setName] = useState(user?.name || ''); // State for user name
    const [email, setEmail] = useState(user?.email || ''); // State for user email
    const [bio, setBio] = useState(user?.bio || ''); // State for user bio
    const [avatar, setAvatar] = useState(user?.avatar || ''); // State for user avatar
    const [isEditing, setIsEditing] = useState(false); // State to toggle edit mode
    const [sidebarOpen, setSidebarOpen] = useState(false); // Sidebar state to control visibility

    // Function to handle the saving of profile details
    const handleSaveProfile = async () => {
        try {
            const updatedUser = { name, email, bio };
            // If a new avatar is set, add it to the updatedUser object
            if (avatar) updatedUser.avatar = avatar;

            // Make API call to save the updated user details
            await axiosInstance.put('/api/user/profile', updatedUser);
            setIsEditing(false); // Exit edit mode
        } catch (err) {
            console.error('Error updating profile:', err);
        }
    };

    // Handle Avatar Change
    const handleAvatarChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setAvatar(reader.result); // Set avatar to base64 data URL
            };
            reader.readAsDataURL(file); // Convert file to base64
        }
    };

    const handleSidebarToggle = () => {
        setSidebarOpen(!sidebarOpen); // Toggle sidebar visibility
    };

    return (
        <Box sx={{ display: 'flex', height: '100vh' }}>
            {/* Sidebar */}
            {sidebarOpen && (
                <Box sx={{ width: '250px', backgroundColor: '#f5f5f5', padding: 2 }}>
                    <Sidebar
                        isSidebarOpen={sidebarOpen}
                        toggleSidebar={handleSidebarToggle}
                    />
                </Box>
            )}

            {/* Main Content */}
            <Box sx={{ flex: 1, padding: 0 }}>
                {/* Navigation Box */}
                <Box>
                    <Navigation onToggle={handleSidebarToggle} /> {/* Pass the toggle function */}
                </Box>

                <Container  sx={{ marginTop: '60px' }}>

                <Box className="UserProfile" sx={{ lineHeight: '0.01', mb: 0.01 }}>
                <Typography variant="body2" component="h2" sx={{ mb: 0.5, fontSize: '0.75rem' }}>
                    User Profile
                </Typography>
                <Typography variant="body2" sx={{ mb: 0.25, fontSize: '0.75rem' }}>
                    ID: {user.id}
                </Typography>
                <Typography variant="body2" sx={{ mb: 0.25, fontSize: '0.75rem' }}>
                    Username: {user.username || 'No username available'}
                </Typography>
                <Typography variant="body2" sx={{ mb: 0.25, fontSize: '0.75rem' }}>
                    Email: {user.email}
                </Typography>
                </Box>

                </Container>    

                <Container sx={{ marginTop: '60px' }}>
                    <Typography variant="h4" gutterBottom>
                        Profile Page
                    </Typography>

                    {/* Profile Information */}
                    <Paper sx={{ padding: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {/* Avatar */}
                        <Avatar
                            src={avatar || user?.avatar || ''}
                            sx={{ width: 100, height: 100, mb: 2 }}
                        />
                        <Button variant="contained" component="label">
                            Change Avatar
                            <input
                                type="file"
                                accept="image/*"
                                hidden
                                onChange={handleAvatarChange}
                            />
                        </Button>

                        {/* Profile Details */}
                        <Typography variant="h5" gutterBottom>
                            {isEditing ? 'Edit Profile' : 'Profile'}
                        </Typography>
                        <Grid container spacing={2}>
                            {/* Name */}
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Name"
                                    variant="outlined"
                                    fullWidth
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    disabled={!isEditing}
                                />
                            </Grid>
                            {/* Email */}
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Email"
                                    variant="outlined"
                                    fullWidth
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    disabled={!isEditing}
                                />
                            </Grid>
                            {/* Bio */}
                            <Grid item xs={12}>
                                <TextField
                                    label="Bio"
                                    variant="outlined"
                                    fullWidth
                                    value={bio}
                                    onChange={(e) => setBio(e.target.value)}
                                    disabled={!isEditing}
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                        </Grid>

                        {/* Edit / Save Button */}
                        <Box sx={{ mt: 2 }}>
                            {isEditing ? (
                                <Button variant="contained" onClick={handleSaveProfile}>
                                    Save Changes
                                </Button>
                            ) : (
                                <Button variant="outlined" onClick={() => setIsEditing(true)}>
                                    Edit Profile
                                </Button>
                            )}
                        </Box>

                        {/* Logout Button */}
                        <Box sx={{ mt: 2 }}>
                            <Button variant="outlined" color="error" onClick={logout}>
                                Logout
                            </Button>
                        </Box>
                    </Paper>
                </Container>
            </Box>
        </Box>
    );
};

export { Profile };

